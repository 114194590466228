<div class="res-listing-wrapper res-listing-mobile" fxLayout="column" fxFlex="0 0 100%">
    <mat-accordion displayMode="flat">
        <mat-expansion-panel [expanded]="true" #expansionPanel>
            <mat-expansion-panel-header> </mat-expansion-panel-header>
            <ng-container [ngTemplateOutlet]="filtersParent"></ng-container>
        </mat-expansion-panel>
    </mat-accordion>

    <mat-progress-bar
        class="progress-table-loader"
        *ngIf="(dataSource.status | async) === 'loading'"
        mode="indeterminate"
    ></mat-progress-bar>
    <mat-table #table [dataSource]="dataSource" matSortDirection="desc" matSortActive="last_action" matSort>
        <ng-container matColumnDef="columns">
            <mat-header-cell *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n="@@image">Image</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let resource" class="pr-0-m">
                <app-image-view
                    [media_id]="resource.media$"
                    [proportional]="!isMobile"
                    [paddingBottom]="'58px'"
                    [smallSpinner]="true"
                ></app-image-view>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="title">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@title"> Title </mat-header-cell>
            <mat-cell *matCellDef="let resource" class="pr-0-m">
                <span *ngIf="!isMobile" class="original">{{ resource.title }}</span>
                <div *ngIf="!isMobile" class="hover">
                    <ng-container
                        [ngTemplateOutlet]="tableSingleRowTools"
                        [ngTemplateOutletContext]="{ resource: resource }"
                    ></ng-container>
                </div>
                <div class="mobile-row-title" *ngIf="isMobile">
                    <div class="mobile-img-wrapper">
                        <app-image-view
                            [smallSpinner]="true"
                            [media_id]="resource.media$"
                            [proportional]="true"
                        ></app-image-view>
                    </div>
                    <div class="info-main-wrapper">
                        <span class="item-title">{{ resource.title }}</span>
                        <div class="nu-items">
                            <span class="nu">
                                {{ resource.number_of_items }}
                            </span>
                            <span class="last-action-mob">{{
                                resource.last_action | kdDateFormat : 'YYYY-MM-DD HH:mm'
                            }}</span>
                        </div>
                    </div>
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="summary">
            <mat-header-cell *matHeaderCellDef> </mat-header-cell>
            <mat-cell [class.no-display-cell]="!resource.bullets" *matCellDef="let resource">
                {{ resource.bullets }}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="occasionStatus">
            <mat-header-cell mat-sort-header *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n="@@status">Status</span>
                </div>
            </mat-header-cell>
            <mat-cell
                *matCellDef="let resource"
                class="pr-0-m"
                [ngStyle]="{ color: (resource | resourceStatus : true)?.color }"
            >
                <span *ngIf="isMobile" class="mobile-label" i18n="@@status">Status</span>
                <mat-icon>
                    {{ (resource | resourceStatus : true)?.icon }}
                </mat-icon>
                &nbsp;
                {{ (resource | resourceStatus : true)?.status }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="last_action">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@lastAction"> Last action </mat-header-cell>
            <mat-cell [class.no-display-cell]="!resource.last_action" *matCellDef="let resource">
                <span *ngIf="isMobile" class="mobile-label" i18n="@@lastAction">Last action</span>
                <span>
                    {{ resource.last_action | kdDateFormat : 'YYYY-MM-DD HH:mm' }}
                </span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="article_code">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n> Short name </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span *ngIf="isMobile" class="mobile-label" i18n>Short name</span>
                <span>
                    {{ resource.article_code }}
                </span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="duration">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@duration"> Duration </mat-header-cell>
            <mat-cell [class.no-display-cell]="!resource.duration" *matCellDef="let resource">
                <span *ngIf="isMobile" class="mobile-label" i18n="@@duration">Duration</span>
                <span>
                    {{ resource?.duration | formatIsoDuration }}
                </span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="price">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@price"> Price </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span *ngIf="isMobile" class="mobile-label" i18n="@@price">Price</span>
                <span *ngIf="resource.price !== 0 && resource.price" class="row-content"
                    >{{ resource.price }}&nbsp;{{ resource.currency }}</span
                >
                <span *ngIf="resource.price === 0 || !resource.price" class="row-content" i18n="@@free">Free</span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="done">
            <mat-header-cell mat-sort-header *matHeaderCellDef i18n="@@completed"> Completed </mat-header-cell>
            <mat-cell [class.no-display-cell]="!resource.done" *matCellDef="let resource">
                <span *ngIf="isMobile" class="mobile-label" i18n="@@completed">Completed</span>
                <span *ngIf="resource.done">
                    {{ resource.done | kdDateFormat : 'YYYY-MM-DD' }}
                </span>
                <!-- <span *ngIf="!resource.done" i18n="@@notYet">Not yet</span> -->
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="opportunity_assign_date">
            <mat-header-cell mat-sort-header *matHeaderCellDef i18n="@@assignedDate"> Assigned date </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span *ngIf="isMobile" class="mobile-label" i18n="@@assignedDate">Assigned date</span>
                <span>
                    {{ resource.opportunity_assign_date | kdDateFormat : 'YYYY-MM-DD' }}
                </span>
                <!-- <span *ngIf="!resource.done" i18n="@@notYet">Not yet</span> -->
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="result">
            <mat-header-cell *matHeaderCellDef i18n="@@result"> Results </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span *ngIf="isMobile" class="mobile-label" i18n="@@result">Results</span>
                <span *ngIf="resource.done">
                    {{ resource.done }}
                </span>
                <!-- <span *ngIf="!resource.done" i18n="@@notYet">Not yet</span> -->
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="certifier">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@certifier"> Certifier </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span *ngIf="isMobile" class="mobile-label" i18n="@@certifier">Certifier</span>
                <span>
                    {{ resource.author_name || $any(resource.author$ | async)?.name }}
                </span>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="user">
            <mat-header-cell *matHeaderCellDef i18n="@@name"> Name </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span *ngIf="isMobile" class="mobile-label" i18n="@@name">Name</span>
                <span>
                    {{ resource.user.name }}
                </span>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="assigned_by">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@assignedBy"> Assigned by </mat-header-cell>
            <mat-cell
                [class.no-display-cell]="!(resource.assigned_by && resource.assigned_by.length > 0)"
                *matCellDef="let resource"
            >
                <span *ngIf="isMobile" class="mobile-label" i18n="@@assignedBy">Assigned by</span>
                <ng-container *ngIf="resource.assigned_by && resource.assigned_by.length > 0">
                    <div class="recommend-wrapper" *ngFor="let assignee of resource.assigned_by">
                        <div class="user-img-wrapper">
                            <div [matTooltip]="assignee.name" class="user-img">
                                {{ assignee.name | nameSign }}
                            </div>
                        </div>
                    </div>
                </ng-container>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="recommended_by">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@recommendedBy"> Recommended by </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span *ngIf="isMobile" class="mobile-label" i18n="@@recommendedBy">Recommended by</span>
                <ng-container *ngIf="resource.recommended_by && resource.recommended_by.length > 0">
                    <div class="recommend-wrapper" *ngFor="let recommendee of resource.recommended_by">
                        <div class="user-img-wrapper">
                            <div [matTooltip]="recommendee.name" class="user-img">
                                {{ recommendee.name | nameSign }}
                            </div>
                        </div>
                    </div>
                </ng-container>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="done_items">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@activityProgress"> Progress </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span *ngIf="isMobile" class="mobile-label" i18n="@@activityProgress">Progress</span>
                <span>
                    <div class="progress-wrapper">
                        <span class="progress" [ngStyle]="{ width: '50%' }"></span>
                        <span class="remain">{{ resource.done_items ? resource.done_items : '0' }}</span>
                        <span class="total">{{ resource.number_of_items }}</span>
                    </div>
                </span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="question_number">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@activityProgress"> Progress </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span *ngIf="isMobile" class="mobile-label" i18n="@@activityProgress">Progress</span>
                <span>
                    <div class="progress-wrapper">
                        <span class="progress" [ngStyle]="{ width: '50%' }"></span>
                        <span class="remain">{{ resource.done_items ? resource.done_items : '0' }}</span>
                        <span class="total">{{ resource.number_of_items }}</span>
                    </div>
                </span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="language_id">
            <mat-header-cell *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n="@@lang">Lang</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span *ngIf="isMobile" class="mobile-label" i18n="@@lang">Lang</span>
                <ng-container>
                    <app-language-selector
                        [language_id]="resource.language_id"
                        class="icon-resource-builder"
                        fxFlex="0 0 auto"
                    ></app-language-selector>
                </ng-container>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="tools">
            <mat-header-cell *matHeaderCellDef> </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span
                    (click)="$event.stopPropagation(); updateComp(resource)"
                    *ngIf="getCircleMetadata(resource) as metadata"
                    [matTooltip]="metadata.tooltip"
                    class="comp-header-Item {{ metadata.class }}"
                    >{{ metadata.value }}</span
                >
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row
            [ngClass]="!resource.loading ? 'link' : 'disableIt'"
            (click)="$event.stopPropagation()"
            *matRowDef="let resource; columns: columns"
        ></mat-row>
    </mat-table>
</div>
<mat-paginator [pageSize]="25" [pageSizeOptions]="[25, 50, 100]"> </mat-paginator>

<ng-template #shareLoadTemp>
    <app-kd-logo-loading size="small"></app-kd-logo-loading>
</ng-template>
<ng-template #filtersParent>
    <app-table-filter
        [filtersTogglesInOnBtn]="true"
        [cacheScope]="AppScope.RESOURCES"
        [inGlobalFilter]="true"
        color="accent"
        #filter
    >
        <app-table-search [inGlobalFilter]="true" class="align-space" searchFilterRef></app-table-search>
        <app-select-filter
            [inGlobalFilter]="true"
            class="filter-space"
            label="Type"
            i18n-label="@@type"
            [property]="{
                name: GlobalFilterProperty.SCOPE,
                type: GlobalFilterPropertyType.RESOURCE_TYPE
            }"
            [options]="resourceTypeOptions"
            scopesFilterRef
        >
        </app-select-filter>
        <app-tag-chip-search-filter
            [inGlobalFilter]="true"
            [languageChange]="langFilter.filterChange"
            fxFlex="50%"
            fxFlex.lt-md="100%"
            fxLayoutAlign="start"
            tagsFilterRef
        ></app-tag-chip-search-filter>
        <app-category-chip-search-filter
            [inGlobalFilter]="true"
            fxFlex="50%"
            fxFlex.lt-md="100%"
            [scope_id]="type_scope_ids"
            categoriesFilterRef
        ></app-category-chip-search-filter>

        <app-select-filter
            [inGlobalFilter]="true"
            class="filter-space"
            label="Status"
            i18n-label
            [property]="GlobalFilterProperty.RESOURCS_OCCASION"
            [options]="resourceStatusOptions"
            resStatusFilterRef
        ></app-select-filter>

        <app-table-date-filter [inGlobalFilter]="true" color="accent" dateFilterRef></app-table-date-filter>

        <app-language-filter
            [inGlobalFilter]="true"
            #langFilter
            fxFlex="0 0 auto"
            fxLayout="row"
            langFilterRef
        ></app-language-filter>
    </app-table-filter>
</ng-template>
<ng-template #tableSingleRowTools let-resource="resource">
    <div class="hover-wrapper" fxLayoutAlign="space-between center">
        <button
            class="hover-tools-btn view"
            mat-raised-button
            matTooltip="View"
            [routerLink]="['/home/dashboard/resource-details/', resource.id]"
            i18n-matTooltip="@@view"
        >
            <mat-icon>visibility</mat-icon>
        </button>
        <ng-container *ngIf="resource.last_publish">
            <button
                class="hover-tools-btn"
                *ngIf="!(shareLoading | async); else shareLoadTemp"
                mat-icon-button
                matTooltip="Share"
                i18n-matTooltip="@@share"
                (click)="shareService.openShareDialog(resource, shareLoading)"
            >
                <mat-icon>share</mat-icon>
            </button>
        </ng-container>
    </div>
</ng-template>
